<template>
  <div class="evo-ring-outer">
    <div class="evo-ring-inner"></div>
  </div>
</template>

<script>
export default {
  name: "EvoRing",
  props: {
    radius: {
      type: String,
      default: "45px",
    },
    color: {
      type: String,
      default: "#faf9f9",
    },
    stroke: {
      type: String,
      default: "18px",
    },
    strokeColor: {
      type: String,
      default: "#dbe3f9",
    },
  },
  mounted() {
    this.setSize();
  },
  methods: {
    setSize() {
      const radius = +this.radius.replace("px", "");
      const stroke = +this.stroke.replace("px", "");
      const diameter = (radius + stroke) * 2;

      this.$el.style.width = diameter + "px";
      this.$el.style.height = diameter + "px";
      this.$el.style.background = this.strokeColor;

      const size = radius * 2;
      this.$el.querySelector(".evo-ring-inner").style.width = size + "px";
      this.$el.querySelector(".evo-ring-inner").style.height = size + "px";
      this.$el.querySelector(".evo-ring-inner").style.background = this.color;
      this.$el.querySelector(".evo-ring-inner").style.left = `calc(50% - ${
        size / 2
      }px)`;
      this.$el.querySelector(".evo-ring-inner").style.top = `calc(50% - ${
        size / 2
      }px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.evo-ring-outer {
  position: relative;
  background: #dbe3f9;
  border-radius: 50%;
  width: 80px;
  height: 80px;

  .evo-ring-inner {
    background: #faf9f9;
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
  }
}
</style>
