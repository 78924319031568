<template>
  <div
    class="section-4 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly,
    }"
  >
    <v-row
      class="px-0 evo-section4-row1"
      align="center"
      justify="space-between"
    >
      <v-col cols="8" sm="8" md="5">
        <div
          class="evo-section4-title evo-text-24"
          :class="{ 'evo-px-text-24': $vuetify.breakpoint.xsOnly }"
          data-aos="fade-up"
        >
          {{ $t("about.our_global_offices") }}
        </div>
      </v-col>
      <v-col cols="1" sm="1" md="1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="1" sm="1" md="1">
        <EvoDiamond />
      </v-col>
    </v-row>
    <v-row
      class="px-0 evo-section4-row2"
      :class="{ 'evo-px-section4-row2': $vuetify.breakpoint.xsOnly }"
    >
      <v-col
        cols="6"
        sm="6"
        md="4"
        v-for="item in items"
        :key="item.key"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="d-flex flex-column evo-item-wrapper">
          <img loading="lazy" 
            class="evo-item-img"
            :class="{ 'evo-px-item-img': $vuetify.breakpoint.xsOnly }"
            :src="item.src"
          />
          <div
            class="evo-item-title evo-text-16"
            :class="{ 'evo-px-text-12': $vuetify.breakpoint.xsOnly }"
          >
            {{ $t(item.title) }}
          </div>
          <div
            class="evo-item-subtitle evo-text-12 evo-opensans-regular"
            :class="{ 'evo-px-text-8': $vuetify.breakpoint.xsOnly }"
          >
            {{ $t(item.tel) }}
          </div>
          <div
            class="evo-item-subtitle evo-text-12 evo-opensans-regular"
            :class="{ 'evo-px-text-8': $vuetify.breakpoint.xsOnly }"
          >
            {{ $t(item.address) }}
          </div>
          <!-- <div
            class="evo-item-subtitle evo-text-12 evo-opensans-regular"
            :class="{ 'evo-px-text-8': $vuetify.breakpoint.xsOnly }"
          >
            {{ $t(item.email) }}
          </div> -->
        </div>
      </v-col>
    </v-row>

    <EvoEllipse
      class="evo-section4-ellipse"
      background="lightYellow"
      transform="rotate(0deg)"
      filter="blur(25px)"
      width="150px"
      height="150px"
    />
    <EvoRect class="evo-section4-rect1" />
    <EvoRing
      class="evo-section4-ring"
      :radius="$vuetify.breakpoint.xsOnly ? '25px' : '62px'"
      :stroke="$vuetify.breakpoint.xsOnly ? '13px' : '31px'"
      color="#f4f6f9"
      strokeColor="#2161FF"
    />
  </div>
</template>

<script>
import EvoDiamond from "@/components/Diamond.vue";
import EvoEllipse from "@/components/Ellipse.vue";
import EvoRect from "@/components/Rect.vue";
import EvoRing from "@/components/Ring.vue";
import { aboutUsSections4Mixin } from '@/mixins/about-us.js';
export default {
  name: "EvoAboutUsSectionFour",
  components: {
    EvoDiamond,
    EvoEllipse,
    EvoRect,
    EvoRing,
  },
  mixins: [aboutUsSections4Mixin]
};
</script>

<style lang="scss" scoped>
.section-4 {
  position: relative;

  .evo-section4-row1 {
    margin-bottom: 35px;
  }

  .evo-section4-row2 {
    margin-bottom: 20px;
  }

  .evo-px-section4-row2 {
    margin-bottom: 40px;
  }

  .evo-section4-title {
    font-weight: bold;
    color: #000000;
  }

  .evo-item-wrapper {
    .evo-item-img {
      width: 45px;
      height: auto;
      margin-bottom: 7px;
    }

    .evo-px-item-img {
      width: 45px;
      height: auto;
    }

    .evo-item-title {
      font-weight: bold;
      color: #1f252d;
      margin-bottom: 7px;
    }

    .evo-item-subtitle {
      color: #a0a3a8;
      margin-bottom: 5px;
    }
  }

  .evo-section4-ellipse {
    position: absolute;
    right: 30px;
    bottom: 130px;
  }

  .evo-section4-rect1 {
    position: absolute;
    width: 237px;
    height: 47px;
    right: 0;
    bottom: 30px;
  }

  .evo-section4-ring {
    position: absolute;
    left: -58px;
    bottom: 25px;
  }
}
</style>
