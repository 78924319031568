<template>
  <div class="section-4 evo-mobile mx-auto">
    <div class="evo-section4-header d-flex justify-space-between align-center">
      <div class="evo-section4-title evo-text-41">
        {{ $t('about.our_global_offices') }}
      </div>
      <div class="evo-text-line"></div>
    </div>

    <v-row class="evo-section4-list">
      <v-col cols="6" v-for="item in items" :key="item.key">
        <div class="d-flex flex-column evo-item-wrapper">
          <img loading="lazy" class="evo-item-img" :src="item.src" />
          <div class="evo-item-title evo-text-32">
            {{ $t(item.title) }}
          </div>
          <div class="evo-item-subtitle evo-text-26 evo-opensans-regular">
            {{ $t(item.tel) }}
          </div>
          <div class="evo-item-subtitle evo-text-26 evo-opensans-regular">
            {{ $t(item.address) }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { aboutUsSections4Mixin } from '@/mixins/about-us.js';
export default {
  name: 'EvoMAboutUsSectionFour',
  mixins: [aboutUsSections4Mixin]
};
</script>

<style lang="scss" scoped>
.section-4 {
  .evo-section4-title {
    font-weight: bold;
    color: #000000;
  }

  .evo-section4-header {
    margin: 38px 0;
    .evo-text-line {
      width: 69px;
      height: 0;
      opacity: 0.13;
      border-bottom: 2px solid #4a4f55;
    }
  }

  .evo-section4-list {
    margin-bottom: 40px;
    .evo-item-wrapper {
      .evo-item-img {
        width: 72px;
        height: auto;
        margin-bottom: 18px;
      }

      .evo-item-title {
        font-weight: bold;
        color: $title_color;
        margin-bottom: 18px;
      }

      .evo-item-subtitle {
        color: #a0a3a8;
        line-height: 1.5;
        margin-bottom: 18px;
      }
    }
  }
}
</style>
