export const aboutUsSections4Mixin = {
  data: () => ({
    items: [
      {
        key: 1,
        src: require('@/assets/about-us/section4-1.png'),
        title: 'about.title_section_4_1',
        address: 'about.address_section_4_1',
        email: 'about.email_section_4_1',
        tel: 'about.tel_section_4_1'
      },
      {
        key: 2,
        src: require('@/assets/about-us/section4-2.png'),
        title: 'about.title_section_4_2',
        address: 'about.address_section_4_2',
        email: 'about.email_section_4_2',
        tel: 'about.tel_section_4_2'
      },
      {
        key: 3,
        src: require('@/assets/about-us/section4-3.png'),
        title: 'about.title_section_4_3',
        address: 'about.address_section_4_3',
        email: 'about.email_section_4_3',
        tel: 'about.tel_section_4_3'
      },
      {
        key: 4,
        src: require('@/assets/contact-us/section2-4.png'),
        title: 'about.title_section_4_4',
        address: 'about.address_section_4_4',
        email: 'about.email_section_4_4',
        tel: 'about.tel_section_4_4'
      },
      {
        key: 5,
        src: require('@/assets/contact-us/section2-5.png'),
        title: 'about.title_section_4_5',
        address: 'about.address_section_4_5',
        email: 'about.email_section_4_5',
        tel: 'about.tel_section_4_5'
      }
    ]
  })
};
